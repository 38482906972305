import React, { useState } from "react";
import { Link } from "react-router-dom";
import Medias from "../inc/Medias";
import config from "../app.config.json";
import toast from "react-hot-toast";
import axios from "axios";

export default function Footer() {
  const [isLoading, setIsLoading] = useState(false); // État pour éviter les appels multiples
  const [values, setValues] = useState({ email: "" });
  const handleSubmitTheNewLetter = (e) => {
    e.preventDefault();
    if (values.email !== "") {
      setIsLoading(true);
      // insert The New letter
      toast.promise(
        new Promise(async (resolve, reject) => {
          try {
            const url = `${config.backend.dev.SERVER}/new-newletter`;
            let sessionId = localStorage.getItem("visitor-session-id");
            console.log(sessionId);

            const request = await axios.post(
              url, // Use the appropriate URL
              values, // Request body should be the second parameter
              {
                headers: {
                  "Content-Type": "application/json",
                  "visitor-session-id": sessionId || "",
                }, // Headers should be the third parameter
              }
            );
            if (request.headers.get("visitor-session-id")) {
              sessionId = request.headers.get("visitor-session-id");
              localStorage.setItem("visitor-session-id", sessionId);
            }
            resolve(request.data.message);
          } catch (error) {
            console.log(error);
            reject(error.response?.data?.message || "An error occurred");
          } finally {
            setIsLoading(false);
          }
        }),

        {
          loading: "Enregistrement en cours!",
          success: (data) => data,
          error: (err) => err,
        },
        {
          position: "bottom-center",
          success: {
            duration: 5000,
            icon: "🔥",
          },
        }
      );
    }
  };
  return (
    <footer className="pt-3 text-center text-white position-relative z-1">
      <div className="overlay z-n1 start-0"></div>
      <div className="container">
        <div className="footer-content w-lg-50 m-auto">
          <div className="footer-logo mb-4 pt-1">
            <Link to="/">
              <img src={Medias.logo} className="w-50" alt="footer-logo" />
            </Link>
          </div>
          <div className="footer-disciption border-bottom border-white border-opacity-25 m-auto mb-6">
            <p className="mb-3">
              Inscrivez-vous à notre newsletter pour ne rien manquer de nos
              actualités.
            </p>
            <div>
              <form
                id="form"
                onSubmit={!isLoading ? handleSubmitTheNewLetter : () => {}}
              >
                <p className="getMeNotify">Tenez-vous informé !</p>

                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Votre email"
                    onChange={(e) =>
                      setValues({ ...values, email: e.target.value })
                    }
                  />
                  <button type="submit" className="btn btn my-3 p-2 px-4">
                    <span>S'inscrire</span>
                  </button>
                </div>
              </form>
            </div>
            <div className="footer-socials pb-6">
              <ul className="m-0 p-0 d-flex gap-3 justify-content-center">
                <li className="d-inline">
                  <Link
                    target="_blank"
                    to={config.socials.facebook}
                    className="d-inline-block rounded-circle bg-white bg-opacity-25"
                  >
                    <i className="bi bi-facebook"></i>
                  </Link>
                </li>
                <li className="d-inline">
                  <Link
                    target="_blank"
                    to={config.socials.instagram}
                    className="d-inline-block rounded-circle bg-white bg-opacity-25"
                  >
                    <i className="bi bi-instagram"></i>
                  </Link>
                </li>
                <li className="d-inline">
                  <Link
                    target="_blank"
                    to={config.socials.tiktok}
                    className="d-inline-block rounded-circle bg-white bg-opacity-25"
                  >
                    <i className="bi bi-tiktok"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright pb-6 pt-1">
          <small>
            Copyright &#169; 2024 Allwhite. Developped by
            <Link to="https://www.linkedin.com/in/ndfranz" target="_blank">
              {" "}
              NDFranz
            </Link>
            . All rights reserved.
          </small>
        </div>
      </div>
    </footer>
  );
}