import React from "react";
import { Link } from "react-router-dom";
import config from "../../app.config.json";

export default function FaqPrevente() {
  return (
    <div
      className="col-lg-6 px-4 mb-2"
      data-aos="fade-right"
      data-aos-delay="200"
    >
      <div className="faq-general">
        <div className="section-title pb-3 text-center text-lg-start">
          <p className="mb-1 pink">QUESTIONS GÉNÉRALES</p>
          <h2 className="mb-2 text-white">
            FOIRE AUX <span className="pink">QUESTIONS!!</span>  !!
          </h2>
          <p className="text-white">
            Vous avez des questions ? Nous avons les réponses !
          </p>
        </div>
        <div className="faq-accordion p-4 mb-5 bg-white rounded">
          <div className="accordion accordion-faq" id="accordionFlushExample">
            <div className="accordion-item">
              <p className="accordion-header p-4">
                <button
                  className="accordion-button collapsed fw-semibold p-0"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  Comment puis-je acheter un billet ?
                </button>
              </p>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body bg-lightgrey p-6">
                  Pour acheter votre billet, il suffit de nous contacter
                  directement sur WhatsApp au{" "}
                  <Link
                    target="_blank"
                    className="text-dark text-bold"
                    to={`https://wa.me/+228${
                      config.phones.moov
                    }?text=${encodeURIComponent(
                      "https://allwhiteworld.com Achat de billet de pré-vente ALLWHITE LOME 6"
                    )}`}
                  >
                    <i className="bi bi-whatsapp"></i> {config.phones.moov}
                  </Link>
                  . Notre équipe se fera un plaisir de vous guider dans votre
                  achat.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <p className="accordion-header p-4">
                <button
                  className="accordion-button collapsed fw-semibold p-0"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  Comment se fait la livraison des billets ?
                </button>
              </p>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body bg-lightgrey p-6">
                  Pour encore plus de confort, nous vous proposons une livraison
                  à domicile de votre billet. Un livreur se chargera de vous le
                  remettre. Des frais de livraison seront ajoutés à votre
                  commande.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <p className="accordion-header p-4">
                <button
                  className="accordion-button collapsed fw-semibold p-0"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  Puis-je payer mon billet en plusieurs fois ?
                </button>
              </p>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body bg-lightgrey p-6">
                  Vous pouvez acheter autant de billets que vous le souhaitez
                  pendant toute la durée de la prévente. Notre service client
                  est à votre disposition pour vous accompagner dans vos achats.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
