import { useState, useEffect } from "react";

export default function GetUserLocationAndRoute() {
  const [locationPermission, setLocationPermission] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if the user is on a mobile device
    setIsMobile(/Mobi|Android/i.test(navigator.userAgent));
  }, []);

  const getUserLocation = () => {
    if (!navigator.geolocation) {
      alert("La géolocalisation n'est pas supportée par votre navigateur.");
      return;
    }

    // Request the current position directly to avoid issues in in-app browsers
    navigator.geolocation.getCurrentPosition(success, error);
  };

  function success(position) {
    const userLat = position.coords.latitude;
    const userLng = position.coords.longitude;

    // Destination coordinates for WINNER BEACH
    const targetLat = 6.1316603;
    const targetLng = 1.2507396;

    // Build Google Maps URL
    const mapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${userLat},${userLng}&destination=${targetLat},${targetLng}&travelmode=driving`;
    window.open(mapsUrl, "_blank");
  }

  function error(err) {
    setLocationPermission("denied");
    if (err.code === err.PERMISSION_DENIED) {
      if (isMobile) {
        alert(
          "Veuillez activer la géolocalisation dans les paramètres de votre navigateur."
        );
        window.open("chrome://settings/content/location", "_blank");
      } else {
        alert(
          "Vous avez refusé l'accès à la géolocalisation. Veuillez l'activer dans vos paramètres."
        );
      }
    } else {
      alert(`Impossible d'obtenir votre position. Erreur : ${err.message}`);
    }
  }

  return (
    <>
      <button onClick={getUserLocation} className="btn btn1 w-100">
        <i className="bi bi-geo-alt"></i> Obtenir l'itinéraire
      </button>
      {locationPermission === "denied" && (
        <p style={{ color: "red", marginTop: "10px" }}>
          Accès à la géolocalisation refusé. Veuillez l'activer dans vos
          paramètres.
        </p>
      )}
    </>
  );
}
