import React, { useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Medias from "../../inc/Medias";

const images = [
  Medias.group02,
  Medias.group03,
  Medias.gallery03,
  Medias.gallery04,
  Medias.gallery05,
  Medias.gallery06,
  Medias.gallery07,
  Medias.gallery09,
  Medias.group01,
  Medias.gallery11,
  Medias.gallery10,
  Medias.gallery12,
  Medias.gallery14,
];

export default function Gallery() {
  const [dataImage, setDataImage] = useState({ img: "", i: -1 });

  // Opens the image modal
  const viewImage = (e,img, i) => {
    e.preventDefault();
    setDataImage({ img, i });
  };

  // Navigates to the previous image
  const prevImage = () => {
    setDataImage((prevState) => {
      const newIndex = prevState.i === 0 ? images.length - 1 : prevState.i - 1;
      return { img: images[newIndex], i: newIndex };
    });
  };

  // Navigates to the next image
  const nextImage = () => {
    setDataImage((prevState) => {
      const newIndex = prevState.i === images.length - 1 ? 0 : prevState.i + 1;
      return { img: images[newIndex], i: newIndex };
    });
  };

  // Closes the image modal
  // const closeModal = () => {
  //   setDataImage({ img: "", i: -1 });
  // };

  return (
    <>
      <div className="gallery py-10">
        <div className="container">
          <div className="gallery-inner">
            <div className="gallerybox">
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}
              >
                <Masonry gutter="5px">
                  {images.map((image, i) => (
                    <div
                      key={i}
                      style={{
                        width: "100%",
                        display: "block",
                        cursor: "pointer",
                      }}
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#gallerycaption"
                      onClick={(e) => viewImage(e, image, i)}
                    >
                      <LazyLoadImage
                        src={image}
                        alt={`Image ${i}`}
                        effect="blur"
                        style={{
                          width: "100%",
                          display: "block",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </div>
          </div>
        </div>
      </div>

      {/* MODAL */}
      <div
        className="modal fade ndf-modal-bg"
        id="gallerycaption"
        tabIndex="-1"
        aria-labelledby="gallerycaptionLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg bg-transparent">
          <div className="modal-content bg-transparent border-0">
            <div className="modal-header border-0 bg-transparent">
              <button
                type="button"
                className="btn-close color-white bg-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body p-0 bg-transparent">
              {dataImage.img && (
                <div className="position-relative d-flex justify-content-center align-items-center">
                  <img
                    src={dataImage.img}
                    className="animate-zoom rounded img-fluid gal-img"
                    alt={`Selected ${dataImage.i}`}
                  />
                  <button className="ndf-previous" onClick={prevImage}>
                    <i className="bi bi-arrow-left"></i>
                  </button>
                  <button className="ndf-next" onClick={nextImage}>
                    <i className="bi bi-arrow-right"></i>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
