import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/bootstrap.min.css";
import "./assets/css/plugin.css";
import "./assets/css/default.css";
import "./assets/css/styles.css";
import "./assets/bootstrap-icons/bootstrap-icons.min.css";
import App from "./App";

import { HashRouter as Router } from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);