import React from "react";
import FaqPrevente from "../inc/Faq-prevente";
import FormPreventTicket from "../inc/FormPreventTicket";
import FormTicket from "../inc/FormTicket";

export default function BuyTicket() {
  return (
    <section className="faq pb-0 overflow-visible d-block position-relative z-1 pt-5">
      <div className="overlay bg-blue opacity-25 z-n1"></div>
      <div className="container">
        <div>
          <div className="row align-items-end">
            <FaqPrevente />
            {/* form ticket */}
            <div className="col px-4 mb-2 align-self-start" id="byTicket">
              <div className="">
                <div className="section-title pb-3 text-center text-lg-start">
                  <h2 className="mb-2 text-white">
                    PASSEZ VOTRE COMMANDE<span className="pink"> ICI</span>
                  </h2>
                </div>
                {/* <FormTicket /> */}
                <FormPreventTicket />
              </div>
            </div>
            {/* /form ticket */}
          </div>
        </div>
      </div>
    </section>
  );
}
