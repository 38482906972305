import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "./Routes";

function App() {
  useEffect(() => {
    try {
      // Désactiver le clic droit sur l'ensemble du document
      document.addEventListener("contextmenu", (e) => e.preventDefault());

      // Désactiver le clic droit et le glisser-déposer sur toutes les images
      document.querySelectorAll("img").forEach((img) => {
        img.onclick = (e) => e.preventDefault();
        img.oncontextmenu = (e) => e.preventDefault();
        img.ondragstart = () => false;
      });
      // Désactiver les combinaisons de touches couramment utilisées pour l'inspection
      const handleKeyDown = (e) => {
        if (
          (e.ctrlKey && e.key === "u") || // Désactiver Ctrl + U
          (e.ctrlKey && e.shiftKey && e.key === "i") || // Désactiver Ctrl + Shift + I (DevTools)
          (e.ctrlKey && e.shiftKey && e.key === "c") || // Désactiver Ctrl + Shift + C (Inspect Element)
          (e.ctrlKey && e.shiftKey && e.key === "j") || // Désactiver Ctrl + Shift + J (Console)
          (e.key === "F12") // Désactiver F12 (DevTools)
        ) {
          e.preventDefault();
        }
      };

      window.addEventListener("keydown", handleKeyDown);

      // Nettoyage lors du démontage du composant
      return () => {
        document.removeEventListener("contextmenu", (e) => e.preventDefault());
        window.removeEventListener("keydown", handleKeyDown);
      };
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={route.element}
          Component={route.Component}
        />
      ))}
    </Routes>
  );
}

export default App;
