import React from "react";
import WhatsAppButton from "./WhatsAppButton";

export default function FormPreventTicket() {
  return (
    <div className="col align-self-center">
      <div className="faq-form rounded mb-mi">
        <div className="form-title mb-4 d-none">
          <p className="mb-1 text-white">
            BILLET{" "}
            <span
              className="text-secondary"
              style={{ textDecoration: "line-through" }}
            >
              15 500 XOF
            </span>{" "}
            <strong className="pink" style={{ fontSize: "1.7rem" }}>
              13 000 XOF
            </strong>
          </p>
          <h2 className="mb-2 text-white">
            ACHAT DE <span className="pink">BILLET</span>
          </h2>
          <p className="m-0 text-white">
            Profitez de <strong>-16,13%</strong> sur tous nos billets jusqu'au{" "}
            <strong className="pink">08 Novembre 2024</strong> !
          </p>
        </div>
        <WhatsAppButton />
      </div>
    </div>
  );
}
