import React from "react";
import Medias from "../../inc/Medias";

export default function Overview() {
  return (
    <section className="overview pb-0">
      <div className="container">
        <div className="inner-overview position-relative border-dashed-bottom-2 nfranz-padding">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="overview-left text-center text-lg-start"
                data-aos="fade-right"
              >
                <div className="overview-title pb-4">
                  <p className="mb-1 pink">APERÇU</p>
                  <h2 className="mb-2">
                    OBTENEZ LES DERNIÈRES INFOS SUR
                    <span className="pink"> ALL WHITE</span>
                  </h2>
                  <p>
                    All White Lomé 6 : l'événement qui vous fera briller de
                    mille feux. Laissez-vous emporter par la magie de cette
                    rencontre exceptionnelle. Musique, danse, et surprises vous
                    attendent. Prêt à vivre une expérience unique ? Découvrez
                    vite ce qui vous réserve !
                  </p>
                  <p className="mb-2">Blanc comme neige, chaud comme le feu.</p>
                </div>
                <div className="overview-event-info pb-6 g-4 text-start position-absolute">
                  <div className="row justify-content-around">
                    <div className="col-lg-6 col-md-6">
                      <div className="event-info-box align-items-center d-flex p-4 rounded bg-white box-shadow my-2">
                        <div className="event-info-icon text-center">
                          <i className="fa fa-map-marker text-white bg-pink rounded-circle me-3"></i>
                        </div>
                        <div className="location-info">
                          <h5 className="mb-1 text-capitalise">Où</h5>
                          <small className="text-capitalises text-secondary">
                            WINNER BEACH Lomé
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="event-info-box align-items-center d-flex p-4 rounded bg-white box-shadow my-2">
                        <div className="event-info-icon text-center">
                          <i className="fa fa-calendar-o text-white bg-pink rounded-circle me-3"></i>
                        </div>
                        <div className="time-info">
                          <h5 className="mb-1 text-capitalise">QUAND</h5>
                          <small>
                            Dimanche
                            <br />
                            le 15 Dec, 2024 à partir de 12h:00
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex gap-3 col-12 justify-content-end">
                      <div className="d-md-flex gap-3 col-12 py-2 justify-content-end">
                        <figure className="auhtor-signature">
                          <img
                            src={Medias.bloggable}
                            alt=""
                            style={{ width: "142px" }}
                          />
                        </figure>
                        <div className="author-detail">
                          <h6 className="author-name">MAXIME FERGANY</h6>
                          <span className="author-prof">Organisateur</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="overview-img">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-6">
                    <div className="container-img-left mb-2">
                      <div
                        className="col-12 my-2"
                        data-aos="zoom-in"
                        data-aos-delay="300"
                      >
                        <div className="container-img-right w-lg-100">
                          <img
                            src={Medias.gallery13}
                            alt="group-image"
                            className="w-100 rounded"
                          />
                        </div>
                      </div>
                      <div
                        className="img-left-1 float-end w-lg-80"
                        data-aos="zoom-in"
                      >
                        <img
                          className="mb-2 w-100 rounded"
                          src={Medias.gallery02}
                          alt="group-image"
                        />
                      </div>
                      <div
                        className="img-left-1"
                        data-aos="zoom-in"
                        data-aos-delay="200"
                      >
                        <img
                          src={Medias.gallery01}
                          alt="group-image"
                          className="w-100 rounded"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-6 col-md-6"
                    data-aos="zoom-in"
                    data-aos-delay="300"
                  >
                    <div className="container-img-right w-lg-75">
                      <img
                        src={Medias.gallery08}
                        alt="group-image"
                        className="w-100 rounded"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
