import React from "react";
import { Link as ScrollLink } from "react-scroll";
import Medias from "../../inc/Medias";

export default function Pricing() {
  // const [plan, setPlan] = useState("");
  return (
    <section className="price bg-lightgrey">
      <div className="container">
        <div className="price-inner text-center">
          <div className="price-title mb-7 w-lg-60 m-auto" data-aos="fade-down">
            <p className="mb-1 pink">TABLEAU DES PRIX</p>
            <h2 className="mb-1">
              EXPLOREZ LES PLANS <span className="pink">TARIFAIRES</span>{" "}
              FLEXIBLES
            </h2>
            <p className="m-0">
              Nos plans s'adaptent à votre rythme. Payez seulement pour ce dont
              vous avez besoin.
            </p>
          </div>
          <div className="price-options g-2 pb-6">
            <div className="row justify-content-center">
              {/* plan 1 */}
              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="ticket-box">
                  <div className="ticket-box-inner bg-white position-relative rounded-bottom-5">
                    <img
                      src={Medias.pattern01}
                      alt="pattern"
                      className="w-100 pattern position-absolute rounded-top"
                    />
                    <div className="ticket-title">
                      <h4 className="pt-6 mb-1">SIMPLE</h4>
                      <p>solo</p>
                    </div>
                    <div className="ticket-price bg-white py-6">
                      <div className="ticket-price-inner d-flex justify-content-center align-items-start black fw-bold">
                        <span className="me-1 m-0 dollor">15 000</span>
                        <span className="me-1 mb-0 cents">XOF</span>
                      </div>
                      <p className="m-0">Par personne</p>
                    </div>
                    <div className="ticket-button m-auto p-6">
                      <ScrollLink
                        className="btn"
                        to="byTicket"
                        spy={true}
                        smooth={true}
                        duration={200}
                      >
                        Choisir
                      </ScrollLink>
                      <p className="mt-4">
                        <small>Viens vivre l'expérience !</small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Plan 2 */}
              <div
                className="col-lg-4 col-md-6 d-none"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="ticket-box-inner bg-white position-relative rounded-bottom-5">
                  <img
                    src={Medias.pattern02}
                    alt="pattern"
                    className="w-100 pattern position-absolute rounded-top"
                  />
                  <div className="ticket-title py-6 bg-pink text-white">
                    <h4 className="text-white mb-1">SOLO</h4>
                    <p>Billet de pré-vente</p>
                  </div>
                  <div className="ticket-price bg-white pt-6 pb-0">
                    <div className="ticket-price-inner d-flex justify-content-center align-items-start fw-bold">
                      <div className="mb-3 position-relative">
                        <span className="dollor-dotted">15 500</span>
                      </div>
                    </div>
                    <div className="ticket-price-inner d-flex justify-content-center align-items-start fw-bold pb-2">
                      <span className="me-1 m-0 dollor pink">13 000</span>
                      <span className="me-1 mb-0 cents pink">XOF</span>
                    </div>
                    <p
                      style={{ width: "90%", margin: "0 auto" }}
                      className="text-center"
                    >
                      Profitez de <strong>-16,13%</strong> sur tous nos billets
                      jusqu'au <br />
                      <strong className="pink">08 Novembre 2024</strong> !
                    </p>
                  </div>
                  <div className="partner-button m-auto p-6">
                    <ScrollLink
                      className="btn"
                      to="byTicket"
                      spy={true}
                      smooth={true}
                      duration={200}
                    >
                      Choisir
                    </ScrollLink>
                    <p className="mt-4">
                      <small className="text-italic">
                        Blanc éclatant, prix doux : Notre promo vous attend !
                      </small>
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="ticket-box-inner bg-white position-relative rounded-bottom-5">
                  <img
                    src={Medias.pattern02}
                    alt="pattern"
                    className="w-100 pattern position-absolute rounded-top"
                  />
                  <div className="ticket-title py-6 bg-pink text-white">
                    <h4 className="text-white mb-1">COUPLE</h4>
                    <p>Billet de couple</p>
                  </div>
                  <div className="ticket-price bg-white pt-6 pb-0">
                    <div className="ticket-price-inner d-flex justify-content-center align-items-start fw-bold d-none">
                      <div className="mb-3 position-relative">
                        <span className="dollor-dotted">28 000</span>
                      </div>
                    </div>
                    <div className="ticket-price-inner d-flex justify-content-center align-items-start fw-bold pb-2">
                      <span className="me-1 m-0 dollor pink">28 000</span>
                      <span className="me-1 mb-0 cents pink">XOF</span>
                    </div>
                    <p
                      style={{ width: "90%", margin: "0 auto" }}
                      className="text-center"
                    >
                      Couple
                    </p>
                  </div>
                  <div className="partner-button m-auto p-6">
                    <ScrollLink
                      className="btn"
                      to="byTicket"
                      spy={true}
                      smooth={true}
                      duration={200}
                    >
                      Choisir
                    </ScrollLink>
                    <p className="mt-4">
                      <small className="text-italic">
                        Blanc éclatant, prix doux : Notre promo vous attend !
                      </small>
                    </p>
                  </div>
                </div>
              </div>
              {/* plan 3 */}
              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="ticket-box-inner bg-white position-relative rounded-bottom-5">
                  <img
                    src={Medias.pattern01}
                    alt="pattern"
                    className="w-100 pattern position-absolute rounded-top"
                  />
                  <div className="ticket-title">
                    <h4 className="pt-6 mb-1">GROUPE</h4>
                    <p>Par groupe de 8 personne max</p>
                  </div>
                  <div className="ticket-price bg-white py-6">
                    <div className="ticket-price-inner d-flex justify-content-center align-items-start black fw-bold">
                      <span className="me-1 m-0 dollor">110 000</span>
                      <span className="me-1 mb-0 cents">F</span>
                    </div>
                    <p className="m-0">8 personnes</p>
                  </div>
                  <div className="partner-button m-auto p-5">
                    <ScrollLink
                      className="btn"
                      to="byTicket"
                      spy={true}
                      smooth={true}
                      duration={200}
                    >
                      Choisir
                    </ScrollLink>
                    <p className="mt-4">
                      <small>Vivez un moment privilégié</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
