import logo from "../assets/images/logo/004.png";
import bannerImage from "../assets/images/team/06.png";
import bannerImage02 from "../assets/images/team/07.png";
import bloggable from "../assets/images/group/bloggable-img14.png";
import group01 from "../assets/images/group/046.png";
import group02 from "../assets/images/group/048.png";
import group03 from "../assets/images/group/047.png";
import partener01 from "../assets/images/icon/1.png";
import pattern01 from "../assets/images/pattern/1.png";
import pattern02 from "../assets/images/pattern/002.png";
import solo from "../assets/images/qrcode/solo.png";
import groupe from "../assets/images/qrcode/groupe.png";
import couple from "../assets/images/qrcode/couple.png";
import qrcode from "../assets/images/qrcode/qrcode.png";

import gallery01 from "../assets/images/gallery/FB_IMG_1729598382469.jpg";
import gallery02 from "../assets/images/gallery/FB_IMG_1729598392420.jpg";
import gallery03 from "../assets/images/gallery/FB_IMG_1729598441814.jpg";
import gallery04 from "../assets/images/gallery/FB_IMG_1729598460489.jpg";
import gallery05 from "../assets/images/gallery/FB_IMG_1729598787931.jpg";
import gallery06 from "../assets/images/gallery/FB_IMG_1729598911365.jpg";
import gallery07 from "../assets/images/gallery/FB_IMG_1729598922188.jpg";
import gallery08 from "../assets/images/gallery/FB_IMG_1729598949188.jpg";
import gallery09 from "../assets/images/gallery/FB_IMG_1729599026223.jpg";
import gallery10 from "../assets/images/gallery/FB_IMG_1729599064497.jpg";
import gallery11 from "../assets/images/gallery/FB_IMG_1729599146168.jpg";
import gallery12 from "../assets/images/gallery/DSC_4620.png";
import gallery13 from "../assets/images/gallery/DSC_4795.png";
import gallery14 from "../assets/images/gallery/DSC_4923.png";
import flyerPrevente from "../assets/images/gallery/prevente_3.png";
import plusPrevente from "../assets/images/gallery/plus.png";

const Medias = {
  logo,
  bannerImage,
  bannerImage02,
  bloggable,
  group01,
  group02,
  group03,
  partener01,
  pattern01,
  pattern02,
  solo,
  groupe,
  couple,
  qrcode,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  gallery06,
  gallery07,
  gallery08,
  gallery09,
  gallery10,
  gallery11,
  gallery12,
  gallery13,
  gallery14,
  flyerPrevente,
  plusPrevente
};
export default Medias;
