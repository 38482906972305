import config from "../../app.config.json";

function openWhatsAppLink() {
  const isInAppBrowser = /FBAN|FBAV|Instagram|Line|Snapchat|Twitter/i.test(
    navigator.userAgent
  );
  const phone = config.phones.moov;
  const message = encodeURIComponent(
    "`https://allwhiteworld.com` Achat de billet de pré-vente ALLWHITE LOME 6"
  );
  const waLink = `https://wa.me/+228${phone}?text=${message}`;

  if (isInAppBrowser) {
    alert(
      "Ce lien doit être ouvert dans un navigateur externe pour fonctionner. Veuillez copier le lien ci-dessous :\n" +
        waLink
    );
  } else {
    window.location.href = waLink;
  }
}

export default function WhatsAppButton() {
  return (
    <button onClick={openWhatsAppLink} className="btn btn">
      Passer la commande via WhatsApp
    </button>
  );
}
