import React from "react";
import GetUserLocationAndRoute from "../inc/GetUserLocationAndRoute";

export default function Direction() {
  return (
    <section className="direction pb-5" id="map">
      <div className="container">
        <div className="section-title text-center pb-2 w-lg-60 m-auto">
          <p className="mb-1 pink">REJOIGNEZ-NOUS</p>
          <h2 className="mb-1">
            OBTENIR L'ITINÉRAIRE VERS <span className="pink">L'ÉVÉNEMENT</span>
          </h2>
          <p className="mb-0">
            Quelques clics te séparent de l'événement. Rejoins-nous !
          </p>
        </div>
        <div className="direction-content">
          <div className="direction-info">
            <div className="row">
              <div
                className="col-lg-5 col-md-5 px-1"
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
              >
                <div className="p-6 d-flex text-white w-100 h-100 rounded bg-pink">
                  <div className="justify-content-center align-self-center ms-6 ms-sm-0">
                    <h5 className="text-white pb-2">LIEU DE L'ÉVÉNEMENT :</h5>
                    <p className="pb-5 m-0">WINNER BEACH</p>
                    <h5 className="text-white pb-2">ADDRESS:</h5>
                    <p className="pb-5 m-0">
                      47J2+XCM, Boulevard Du Mono, Lomé
                    </p>
                    <h5 className="text-white pb-2">PLUS D'INFO:</h5>
                    <p className="pb-5 m-0">+228 96 00 33 38</p>
                    <GetUserLocationAndRoute />
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-7 px-1 py-2">
                <iframe
                  title="direaction to winner beach"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3316.158095747257!2d1.2507396!3d6.131660299999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1023e1ed6be1e56d%3A0x8c6389358b28bd63!2sWINNER%20BEACH!5e1!3m2!1sen!2stg!4v1727456477678!5m2!1sen!2stg"
                  height="400"
                  className="rounded w-100"
                  allowFullScreen={true}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
