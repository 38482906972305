import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import BackToTop from "../layouts/BackToTop";
import axios from "axios";
import config from "../app.config.json";
import Gallery from "./components/Gallery";
import Banner from "./components/Banner";
import Overview from "./components/Overview";
import Tickets from "./components/Tickets";
import Pricing from "./components/Pricing";
import BuyTicket from "./components/BuyTicket";
import Direction from "./components/Direction";

export default function Home() {
  // default action on the page
  const [visitorRegistered, setVisitorRegistered] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // État pour éviter les appels multiples
  // Fonction pour détecter le type d'appareil
  const detectDeviceType = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent.includes("mobile")) return "Phone";
    if (userAgent.includes("tablet")) return "Tablet";
    return "Computer"; // Par défaut, considérer comme un ordinateur
  };

  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 500,
      easing: "ease-in-out",
      once: false,
      mirror: false,
    });

    // Register visitor
    const registerVisitor = async () => {
      if (isLoading) return; // Prevent double execution
      setIsLoading(true);

      try {
        const existingToken = localStorage.getItem("visitor_token");
        if (existingToken) {
          console.log("Visiteur déjà enregistré !");
          setVisitorRegistered(true);
          return;
        }

        const tokenResponse = await axios.post(
          `${config.backend.dev.SERVER}/generate-token`
        );
        const token = tokenResponse.data.token;

        const { data: existingVisitor } = await axios.get(
          `${config.backend.dev.SERVER}/check-visitor`,
          { params: { token } }
        );
        if (existingVisitor.exists) {
          console.log("Visiteur déjà dans la base.");
          localStorage.setItem("visitor_token", existingVisitor.token);
          setVisitorRegistered(true);
          return;
        }

        // Étape 4: Si non existant, obtenir les informations légales via ipapi.co
        let ipData;
        try {
          const response = await fetch("https://api.ipify.org?format=json");
          const data = await response.json();
          ipData = {
            ip: data.ip,
            city: "",
            region: "",
            country: "",
            latitude: "",
            longitude: "",
            organisation: "",
          };
          console.log(ipData);
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des données IP :",
            error
          );
          return; // Arrêter le processus si une erreur survient ici
        }

        // Déterminer le type d'appareil
        const deviceType = detectDeviceType();
        const visitorData = {
          token: token,
          ip: ipData.ip,
          city: ipData.city,
          region: ipData.region,
          country: ipData.country_name,
          latitude: ipData.latitude,
          longitude: ipData.longitude,
          organisation: ipData.org,
          user_agent: window.navigator.userAgent,
          device_type: deviceType,
        };

        // Étape 5: Envoyer les informations au backend pour stockage
        await axios.post(`${config.backend.dev.SERVER}/visitors_device`, {
          datavisitor: visitorData,
        });
        // Étape 6: Stocker le jeton dans localStorage
        localStorage.setItem("visitor_token", token);

        console.log("Visiteur enregistré avec succès !");
        setVisitorRegistered(true);
      } catch (error) {
        console.error("Erreur lors de l'enregistrement du visiteur :", error);
      } finally {
        setIsLoading(false);
      }
    };

    // Check visitorRegistered state
    if (!visitorRegistered) {
      registerVisitor();
    }
  }, []); // Empty dependency array to run only once on mount

  // RETURN THE APP
  return (
    <>
      {/* HEADER */}
      <Header />
      {/* HEADER */}
      {/* BANNER */}
      <Banner />

      {/* OVERVIEW */}
      <Overview />

      {/* PARTENERS */}
      {/* <Parteners /> */}

      {/* ticket pub */}
      <Tickets />

      {/* Price */}
      <Pricing />

      {/* TICKET BUY */}
      <BuyTicket />

      {/* GALLERY */}
      <Gallery />
      {/* DIRECTION */}
      <Direction />
      {/* FOOTER */}
      <Footer />
      {/* FOOTER */}
      {/* BACK TO TOP */}
      <BackToTop />
      {/* BACK TO TOP */}
    </>
  );
}
