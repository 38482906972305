import React from "react";
import { CountdownTimer } from "../inc/CountDownTimer";
import GetUserLocationAndRoute from "../inc/GetUserLocationAndRoute";
import { Link as ScrollLink } from "react-scroll";
import Medias from "../../inc/Medias";

export default function Banner() {
  return (
    <section
      id="top"
      className="banner position-relative pb-0 banner-mob section-mob"
    >
      <div className="overlay"></div>
      <div className="container">
        <div className="inner-banner position-relative text-white inner-banner-mob">
          <div className="row">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="banner-left text-center pb-lg-5 p-md-0">
                <div className="banner-image ndf-banner-img" data-aos="fade-up">
                  <img
                    src={Medias.plusPrevente}
                    alt="banner-image"
                    className="w-lg-75 w-100"
                    style={{
                      borderRadius: "5px",
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                  />
                  <br />
                </div>
                <div className="countdown position-relative">
                  <div className="triangle-left"></div>
                  <div className="triangle-right"></div>
                  <CountdownTimer isHeader={false} />
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 order-1 order-lg-2 resp-banner"
              data-aos="fade-left"
              data-aos-delay="200"
            >
              <div className="banner-right ms-2 text-center text-lg-start pb-8">
                <div className="text-sm-center d-md-none d-lg-none d-flex justify-content-center mt-3">
                  <CountdownTimer isHeader={true} />
                </div>
                <div className="idition text-sm-center">
                  <img src={Medias.logo} alt="edition-logo" />
                </div>
                <div className="banner-title pb-3">
                  <h4 className="text-white pb-3">
                    LA SIXIEME <span className="pink">EDITION</span> EN 2024
                  </h4>
                  <h1 className="text-white">
                    ALL <span className="pink">WHITE</span> LOME
                  </h1>
                </div>
                <div className="banner-event-info pb-3">
                  <ul className="m-0 ps-0 d-sm-flex justify-content-center justify-content-lg-start list-unstyled">
                    <li className="pe-2 border-end border-1 border-lightgrey add-right">
                      <i className="bi bi-calendar pe-1"></i>Dim 15-DEC 2024
                    </li>
                    <li className="ps-2 add-left">
                      <i className="bi bi-geo-alt pe-1"></i> WINNER BEACH
                    </li>
                  </ul>
                </div>
                <div className="event-discription">
                  <p className="pb-4 m-0">
                    Bienvenue au paradis blanc d'All White Lomé 6. Laissez-vous
                    séduire par l'accueil chaleureux de nos hôtesses, prêtes à
                    vous faire vivre un evenement inoubliable sur une plage de
                    vos rêves.
                  </p>
                  <div className="banner-button">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <ScrollLink
                          className="btn me-3 my-1 w-100"
                          to="byTicket"
                          spy={true}
                          smooth={true}
                          duration={200}
                        >
                          <i className="bi bi-ticket"></i> ACHETER MON BILLET
                        </ScrollLink>
                      </div>
                      <div className="col-lg-6 col-md-6 d-flex align-items-center flex-column">
                        <GetUserLocationAndRoute />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wave overflow-hidden position-absolute w-100 z-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
          className="d-block position-relative"
        >
          <path
            className="elementor-shape-fill"
            d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
          c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
          c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"
          ></path>
        </svg>
      </div>
    </section>
  );
}
