import React, { useEffect, useState } from "react";

export const CountdownTimer = ({ isHeader }) => {
  const targetTime = new Date("2024-12-15T12:00:00");
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const updateCountdown = () => {
      const currentTime = new Date();
      const diff = targetTime - currentTime;

      // Ensure we don't get negative time values
      if (diff < 0) {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        return; // Stop further calculations if the countdown is over
      }

      const d = Math.floor(diff / 1000 / 60 / 60 / 24);
      const h = Math.floor((diff / 1000 / 60 / 60) % 24);
      const m = Math.floor((diff / 1000 / 60) % 60);
      const s = Math.floor((diff / 1000) % 60);

      setTimeLeft({ days: d, hours: h, minutes: m, seconds: s });
    };

    // Initial countdown update
    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, []); // Removed targetTime from dependency array

  return !isHeader ? (
    <div
      id="countdown"
      className="countdown-inner d-flex w-100 p-2 justify-content-center position-relative z-2"
      style={{background: '#ffffff'}}
    >
      <div className="time m-auto py-4">
        <span id="days" className="lh-1 h1 fw-bold ndf-text-colorize">
          {timeLeft.days}
        </span>
        <br />
        <small className="text-secondary">Jours</small>
      </div>
      <div className="time m-auto py-4">
        <span id="hours" className="lh-1 h1 fw-bold ndf-text-colorize">
          {timeLeft.hours < 10 ? "0" + timeLeft.hours : timeLeft.hours}
        </span>
        <br />
        <small className="text-secondary">Heures</small>
      </div>
      <div className="time m-auto py-4">
        <span id="minutes" className="lh-1 h1 fw-bold ndf-text-colorize">
          {timeLeft.minutes < 10 ? "0" + timeLeft.minutes : timeLeft.minutes}
        </span>
        <br />
        <small className="text-secondary">Minutes</small>
      </div>
      <div className="time m-auto py-4">
        <span id="seconds" className="lh-1 h1 fw-bold ndf-text-colorize">
          {timeLeft.seconds < 10 ? "0" + timeLeft.seconds : timeLeft.seconds}
        </span>
        <br />
        <small className="text-secondary">Seconds</small>
      </div>
    </div>
  ) : (
    <div className="d-flex header-counter">
      <div className="time m-auto py-4">
        <span id="days" className="lh-1 h1 fw-bold">
          {timeLeft.days}
        </span>
        <br />
        <small className="text-secondary">Jours</small>
      </div>
      <div className="time m-auto py-4">
        <span id="hours" className="lh-1 h1 fw-bold">
          {timeLeft.hours < 10 ? "0" + timeLeft.hours : timeLeft.hours}
        </span>
        <br />
        <small className="text-secondary">Heures</small>
      </div>
      <div className="time m-auto py-4">
        <span id="minutes" className="lh-1 h1 fw-bold">
          {timeLeft.minutes < 10 ? "0" + timeLeft.minutes : timeLeft.minutes}
        </span>
        <br />
        <small className="text-secondary">Minutes</small>
      </div>
      <div className="time m-auto py-4">
        <span id="seconds" className="lh-1 h1 fw-bold">
          {timeLeft.seconds < 10 ? "0" + timeLeft.seconds : timeLeft.seconds}
        </span>
        <br />
        <small className="text-secondary">Seconds</small>
      </div>
    </div>
  );
};
