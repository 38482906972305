import React from "react";
import { Link as ScrollLink } from "react-scroll";

export default function Tickets() {
  return (
    <section className="ticket position-relative">
      <div className="overlay"></div>
      <div className="container" data-aos="zoom-in">
        <div className="ticket-inner w-lg-75 mx-auto text-center position-relative text-white">
          <div className="ticket-title">
            <h5 className="text-white mb-1">FAISONS-LE VITE</h5>
            <h1 className="text-white mb-2">
              VOUS N'AVEZ PAS ENCORE RÉSERVÉ VOTRE PLACE ?
            </h1>
            <br />
          </div>
          <div className="ticket-info">
            <p>
              Imaginez-vous plongé(e) au cœur de l'action... <br />
              Réservez votre place dès maintenant et profitez de souvenirs
              inoubliables.
            </p>
            <div className="ticket-button">
              <ScrollLink
                className="btn btn1"
                to="byTicket"
                spy={true}
                smooth={true}
                duration={500}
              >
                <i className="bi bi-ticket"></i> COMMANDER MON BILLET
              </ScrollLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
